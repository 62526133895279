import React from "react";
import "./style.scss";
// import "./wp.css";
import ReactHtmlParser from "react-html-parser";
import { FaShoppingCart } from "react-icons/fa";

const Blocks = ({ id, postImg, title, description, index, store, domain, cta_button_type }) => {
  let domain_url = domain?.domain_url;
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }
  return (
    <>
      <div className="post-details">
        <h1 className="title">
          {index + 1}. {title}
        </h1>
        {postImg && (
          <img
            class="in-content"
            src={addStr(postImg, 65, "800_")}
            srcset={`${addStr(postImg, 65, "480_")} 480w, ${addStr(postImg, 65, "800_")} 800w`}
            sizes="(max-width: 600px) 480px, 800px"
            alt={title}
          />
        )}
        {store ? (
          <>
            <div className="main-content">
              {ReactHtmlParser(
                description.replaceAll(
                  "[store-link]",
                  `https://go.${domain_url}/?store_id=${store[0]?.id}&product_id=${id}&domain_id=${domain?.id}`
                )
              )}
            </div>
            {domain && (
              <p class="buttons">
                <a
                  class="button p-button"
                  href={`https://go.${domain_url}/?store_id=${store[0]?.id}&product_id=${id}&domain_id=${domain?.id}`}
                  target="_blank"
                >
                  {cta_button_type == 1 ? (
                    <span>Purchase From {store[0]?.name}</span>
                  ) : cta_button_type == 2 ? (
                    <span>
                      Shop Now
                      <FaShoppingCart
                        style={{ " margin-inline-start": "5px", "padding-top": "5px" }}
                      />
                    </span>
                  ) : cta_button_type == 3 ? (
                    <span>Get Started</span>
                  ) : (
                    <span>BOOK NOW</span>
                  )}
                </a>
              </p>
            )}
          </>
        ) : (
          <div className="main-content">{ReactHtmlParser(description)}</div>
        )}
      </div>
    </>
  );
};

const Content = ({ story, domain }) => {
  let domain_url = domain?.domain_url;
  const { blocks, products } = story || {};

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  const replace_array = [
    { replace_name: "[domain-name]", replace_valve: domain?.name },
    { replace_name: "[domain-url]", replace_valve: domain?.domain_url },
  ];

  let stores = story?.stores;
  let awic_rule = story?.awic_rule;

  stores?.forEach((iterator, i) => {
    let obj = {};
    if (i == 0) {
      if (!awic_rule) {
        obj.replace_name = "[store-link]";
        obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}`;
        replace_array.push(obj);
      } else {
        if (awic_rule?.country?.id) {
          obj.replace_name = "[store-link]";
          obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}&network_account_id=${awic_rule?.awic_network_account?.id}&country_id=${awic_rule?.country?.id}`;
          replace_array.push(obj);
        } else {
          obj.replace_name = "[store-link]";
          obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}&network_account_id=${awic_rule?.awic_network_account?.id}`;
          replace_array.push(obj);
        }
      }
    } else {
      if (!awic_rule) {
        obj.replace_name = `[store-link-${i}]`;
        obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}`;
        replace_array.push(obj);
      } else {
        obj.replace_name = `[store-link-${i}]`;
        obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}&network_account_id=${awic_rule?.awic_network_account.id}&country_id=${awic_rule?.country?.id}`;
        replace_array.push(obj);
      }
    }
  });

  let render_item = story?.content ? story?.content : "";

  replace_array?.forEach(({ replace_name, replace_valve }) => {
    render_item = render_item.replaceAll(replace_name, replace_valve);
  });

  return (
    <div className="article">
      {story?.image && (
        <figure className="image is-5by3">
          <img
            src={addStr(story?.image, 65, "800_")}
            srcset={`${addStr(story?.image, 65, "480_")} 480w, ${addStr(
              story?.image,
              65,
              "800_"
            )} 800w`}
            sizes="(max-width: 600px) 480px, 800px"
            alt={story?.title}
          />
        </figure>
      )}
      <div className="article-title">
        <h1>{story?.title}</h1>
      </div>
      {story?.image && (
        <div className="article-category">
          {story?.categories[0]?.name && <span>{story?.categories[0]?.name}</span>}
        </div>
      )}
      <div className="render-content">
        {story?.content && ReactHtmlParser(render_item)}
        {products && products?.length > 0 && ReactHtmlParser(story?.description)}
        {products && blocks?.length > 0 && ReactHtmlParser(story?.description)}
        {blocks &&
          blocks.map((block, index) => {
            return (
              <Blocks
                id={block.id}
                postImg={block.image}
                title={block.title}
                key={block.id}
                description={block.description}
                className="-blocks"
                count={block.count}
                index={index}
                domain={domain}
              />
            );
          })}
        {products &&
          products?.map((product, index) => {
            return (
              <Blocks
                id={product.id}
                postImg={product.image}
                title={product.name}
                key={product.id}
                description={product.description}
                index={index}
                store={product.stores}
                cta_button_type={story.cta_button_type}
                domain={domain}
              />
            );
          })}
        {story?.products?.length > 0 && ReactHtmlParser(story?.bottom_line)}
      </div>
    </div>
  );
};
export default Content;
